import * as React from "react";
import Helmet from "react-helmet";
import { Mail, Link as LinkIcon } from "react-feather";
import "../styles/social.scss";
import { Content, Feature, Projects, Footer, Link, Value } from "../components";

const seo = {
  title: `Social Responsibility`,
  description: `Our social responsibility defines our success.`,
}

export default () => {
  return (
    <>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="twitter:description" content={seo.description} />
      </Helmet>
      <div className="socialPage">
        <Feature.Mast
          title={seo.description}
          image={Content.CSR.headerImage}
        />

        <header className="socialPage__header">
          <div className="container">
            <p className="h4 socialPage__title">
              As a small company in the Namibian business landscape, Ubuntu
              prides itself by supporting fellow upcoming entrepreneurs in
              various sectors.
              <br /> Below are some of the events and business that Ubuntu is
              committed to supporting.
            </p>
          </div>
          <Projects isMain={true} {...Content.CSR.csr_main} />
        </header>
        <Projects {...Content.CSR.csr_a} />
        <Projects {...Content.CSR.csr_b} />
        <Projects {...Content.CSR.csr_c} />

        <div className="socialPage__cta">
          <div className="container">
            <div className="socialPage__cta-wrap">
              <h5>For sponsorships, charities or requests, contact us</h5>
              <Link
                className="socialPage__cta-link"
                to="mailto:hello@ubuntuevents.co"
              >
                <h3>
                  <Mail className="socialPage__cta-icon" size="20" />
                  hello@ubuntuevents.co
                </h3>
              </Link>
            </div>
          </div>
        </div>
        {/* <Value
          value="ubuntu"
          text="The philosophy of Ubuntu derives from a Nguni word, ubuntu meaning “the quality of being human.” To the observer, ubuntu can be seen and felt in the spirit of willing participation, unquestioning cooperation, warmth, openness, and personal dignity demonstrated by the our people"
        /> */}
        {/* <Form.Contact title="hello@ubuntuevents.co" /> */}
      </div>
      <Footer />
    </>
  );
};
